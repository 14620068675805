'use client';

import { useTranslations } from 'next-intl';

import styles from './index.module.scss';

export default function BaseLoadingComponent () {
    const t = useTranslations();

    return (
        <div className={styles.wrapper}>
            <span className={styles.loadingWord}>
                {t('common.loading')}
            </span>
        </div>
    );
}
